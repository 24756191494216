import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  Section,
  Container,
  SectionTitle,
  SectionDescription,
  BreadCrumb,
} from "../components/Section";
import {
  HeroBanner,
  HeroTitle,
  HeroCaption,
  HeroContent,
  HeroLeft,
  HeroRight,
  HeroCard,
} from "../components/HeroBanner";
import JoinForm from "../components/JoinForm";
import ColorPicker from "../components/ColorPicker";

const ColorOptionsPage = ({ data, location }) => {
  const pageData = data.contentfulPageColorOptions;
  return (
    <Layout location={location}>
      <Seo
        title={pageData.metaTitle}
        description={pageData.metaDescription}
        location={location}
      />
      <Section
        bgColor="#F8FAFB"
        bgBefore="#002765"
        bgBeforeHeight="80%"
        bgBeforeWidth="100%"
      >
        <HeroBanner
          className="banner-hero"
          bgBefore="block"
          data-shadowtext={pageData.heroTitle}
        >
          <HeroCaption>
            <Container maxWidth="1640px">
              <BreadCrumb>
                <Link to="/">Home</Link>
                <span>Color Options</span>
              </BreadCrumb>
              <HeroContent>
                <HeroLeft>
                  <HeroTitle>{pageData.heroTitle}</HeroTitle>
                </HeroLeft>
                <HeroRight>
                  <HeroCard>
                    <JoinForm location={location} />
                  </HeroCard>
                </HeroRight>
              </HeroContent>
            </Container>
          </HeroCaption>
        </HeroBanner>
      </Section>
      <Section
        ept="60px"
        epb="120px"
        xpt="60px"
        xpb="80px"
        pt="40px"
        pb="40px"
        bgColor="#F8FAFB"
        className="section-color"
      >
        <Container>
          <SectionTitle>{pageData.colorOptionsSectionTitle}</SectionTitle>
          <SectionDescription maxWidth="1170px" ml="0">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  pageData.colorOptionsSectionDescription.childMarkdownRemark
                    .html,
              }}
            />
          </SectionDescription>
          <ColorPicker />
        </Container>
      </Section>
    </Layout>
  );
};

export default ColorOptionsPage;

export const pageQuery = graphql`
  query ColorOptionsPageQuery {
    contentfulPageColorOptions {
      metaTitle
      metaDescription
      heroTitle
      colorOptionsSectionTitle
      colorOptionsSectionDescription {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
